import * as React from 'react'
import { FullPageSpinner } from 'modules/common/components/lib'

// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ './authenticated-app'),
// )
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))
function App() {
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <UnauthenticatedApp />
    </React.Suspense>

  );
}

export default App;
