export const base = 'white'
export const text = '#333333'
export const mainHeader = '#626262'
export const subHeader = '#353535'
export const smallHeader = '#595959'
export const blueGradient = 'linear-gradient(180deg, #E6F1F3 11.98%, #E6F1F3 43.23%, #E9F3F5 66.67%, #E9F3F5 100%)'
export const gray = '#F7F7F7'
export const gray10 = '#F2F2F2'
export const gray20 = '#e4e5e9'
export const gray80 = '#6f7077'
export const gray90 = '#cccccc'
export const indigo = '#3f51b5'
export const indigoDarken10 = '#364495'
export const indigoLighten80 = '#b7c1f8'
export const grayDark = '#4D4D4D'
export const yellow = '#F6B61D'
export const yellowLabel = '#FFF9EB'
export const yellowFont = '#CC930B'
export const green = '#4caf50'
export const danger = '#ef5350'
export const orange = 'orange'
