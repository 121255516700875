/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { Link as RouterLink } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react'
import * as colors from 'styles/colors'
// import * as mq from 'styles/media-queries'
// import {Dialog as ReachDialog} from '@reach/dialog'
import { FaSpinner } from 'react-icons/fa'

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const CircleButton = styled.button({
  borderRadius: '30px',
  padding: '0',
  width: '40px',
  height: '40px',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.base,
  color: colors.text,
  border: `1px solid ${colors.gray10}`,
  cursor: 'pointer',
})

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
})
Spinner.defaultProps = {
  'aria-label': 'loading',
}

const buttonVariants = {
  primary: {
    background: colors.yellow,
    color: colors.base,
  },
  secondary: {
    background: colors.gray,
    color: colors.text,
  },
}
const Button = styled.button(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    border: '0',
    textTransform: 'uppercase',
    lineHeight: '19px',
    fontSize: '16px',
  },
  ({ variant = 'primary' }) => buttonVariants[variant],
)

const inputStyles = {
  // border: '1px solid #f1f1f4',
  // background: '#f1f2f7',
  // padding: '8px 12px',
  padding: '17px 16px',
  border: '1px solid #B3B3B3',
  boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.1)',
  background: '#FFFFFF',
}

const Input = styled.input(inputStyles)
const Textarea = styled.textarea(inputStyles)

// const Dialog = styled(ReachDialog)({
//   maxWidth: '450px',
//   borderRadius: '3px',
//   paddingBottom: '3.5em',
//   boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.2)',
//   margin: '20vh auto',
//   [mq.small]: {
//     width: '100%',
//     margin: '10vh auto',
//   },
// })

const FormGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

function FullPageSpinner() {
  return (
    <div
      css={{
        fontSize: '4em',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  )
}

const Link = styled(RouterLink)({
  color: colors.indigo,
  ':hover': {
    color: colors.indigoDarken10,
    textDecoration: 'underline',
  },
})

const errorMessageVariants = {
  stacked: { display: 'block' },
  inline: { display: 'inline-block' },
}

function ErrorMessage({ error, variant = 'stacked', ...props }) {
  return (
    <div
      role="alert"
      css={[{ color: colors.danger }, errorMessageVariants[variant]]}
      {...props}
    >
      <span>There was an error: </span>
      <pre
        css={[
          { whiteSpace: 'break-spaces', margin: '0', marginBottom: -5 },
          errorMessageVariants[variant],
        ]}
      >
        {error.message}
      </pre>
    </div>
  )
}

function FullPageErrorFallback({ error }) {
  return (
    <div
      role="alert"
      css={{
        color: colors.danger,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

export {
  FullPageErrorFallback,
  ErrorMessage,
  CircleButton,
  Spinner,
  Button,
  Input,
  Textarea,
  FormGroup,
  FullPageSpinner,
  Link,
}
