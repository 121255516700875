import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './bootstrap'
import { Profiler } from 'modules/common/components/profiler'
import { AppProviders } from './context'



ReactDOM.render(
  <Profiler id="App Root" phases={['mount']}>
    <AppProviders>
      <App />
    </AppProviders>
  </Profiler>,
  document.getElementById('root')
);